<template>
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card v-if="taskGroupId">
			<v-card-title>Duplicate Task Group</v-card-title>
			<v-card-text>

				<project-picker v-model="selectedProject" label="Copy Group To..." dense class="mt-6" hide-details outlined :disabled="saving" placeholder="Select One...">
					<template v-slot:item="{item}">
						<span class="mr-2">{{item.name}}</span><v-chip small v-if="item.status != 'active'" class="ml-auto">{{item.status}}</v-chip>
					</template>
				</project-picker>

			</v-card-text>

			<v-card-actions>
				<div class="ml-auto">
					<v-btn @click="modal.trigger('close')" :disabled="saving" class="mr-3">Close</v-btn>
					<v-btn color="primary" @click="submit" :loading="saving">Duplicate</v-btn>
				</div>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import moment from "moment";
	import { IdType } from "../../utils/IdType";
	import ProjectPicker from "@/components/fields/projectPicker.vue";

	export default {
		components: { ProjectPicker },
		props: {
			modal: {
				type: Object,
				required: true,
			},
			taskGroupId: {
				type: IdType
			},
			
		},

		data() {
			return {
				saving: false,
				taskGroupData: null,
				selectedProject: null
			};
		},

		computed: {
		},

		methods: {
			submit() {
				this.saving = true;

				this.$apollo
					.mutate({
						mutation: gql`
							mutation ($taskGroupId: ID! $projectId: ID!) {
								duplicateTaskGroup(taskGroupId: $taskGroupId projectId: $projectId) {
									id
									name
									is_default
									is_archived
									project_id
								}
							}
						`,
						variables: {
							taskGroupId: this.taskGroupId,
							projectId: this.selectedProject
						},
						update: (store, { data: { duplicateTaskGroup } }) => {
							this.modal.trigger("save close", duplicateTaskGroup)
						}
					})
					.catch((error) => {
						console.error("error duplicating task group", error);
						this.modalErrorMessage = "There was a problem duplicating the task group.";
					})
					.then(() => this.saving = false);
			},

			copyData(graphQlData = {}) {
				let copyManyFn = (props, subKey = "") => {
					let source = graphQlData;
					if (subKey) {
						source = source[subKey] || {};
					}

					let result = {};
					for (let i of props) {
						result[i] = source.hasOwnProperty(i) ? source[i] : null;
					}

					return result;
				};

				this.taskGroupData = {
					...copyManyFn([
						"name",
						"type",
						"is_archived",
						"is_default",
						"start_date",
					]),
				};
			},

			formModel() {
				let model = {
					name: this.taskGroupData.name,
					// type: this.taskGroupData.type,
					is_default: !!this.taskGroupData.is_default,
					is_archived: !!this.taskGroupData.is_archived,
					start_date: this.taskGroupData.start_date,
				};

				if (this.isUpdate) {
					model.id = this.taskGroupId;
				}
				else {
					model.project_id = this.projectId;
					model.type = this.taskGroupData.type;

				}

				return model;
			},

			// allowedDates(v) {
			// 	let day = moment(v).day();
			// 	return day >= 1 && day <= 5;
			// }
		},

		// watch: {
		// 	taskGroup(to) {
		// 		this.copyData(to);
		// 	},
		// 	// project(to) {
		// 	// 	this.taskGroupData.start_date = to.project_started || to.creation_date;
		// 	// },
		// 	taskGroupId: {
		// 		immediate: true,
		// 		handler(to) {
		// 			if (!to) {
		// 				this.copyData({
		// 					type: 'waterfall'
		// 				});
		// 			}
		// 		},
		// 	},
		// },
	};
</script>